import { Controller } from "stimulus"
import { airbrake } from "../custom/airbrake";
import { setupStripe, StripePaymentRequest } from "../custom/stripe";
import { PayPalExpressCheckout } from "../custom/paypal";
import { showPleaseWait, hidePleaseWait } from "../custom/please-wait";

export default class extends Controller {
    static targets = ["cs", "pt", "bundle", "name", "email", "nameGroup", "emailGroup", "errorMessage"]
    static values = { url: String, product: String, oe: Boolean, ppe: String, ppci: String, pwd: String}

    connect() {
        console.log('express-checkout#connect');
        this.createOrder(this);
    }

    planSelect(ev) {
        ev.preventDefault();

        if (this.urlValue != ev.params.url) {
            this.urlValue = ev.params.url;
            this.productValue = ev.params.product;

            $('.plans .btn').removeClass('selected');
            $(ev.params.type).addClass('selected');
            $('.plans').removeClass('checkout').find('.btn').show();

            // if on checkout step, we need to reload the order
            if (this.oeValue) {
                this.overlay = showPleaseWait();
                this.createOrder(this);
            }
        }

        document.getElementById('checkout').scrollIntoView({
            behavior: 'smooth'
        });
    }

    submit(ev) {
        ev.preventDefault();

        this.overlay = showPleaseWait();

        let self = this;
        if (this.validateForm()) {
            // first we submit to new order to create and order and add the product
            $.ajax({
                url: ev.currentTarget.action,
                data: $(ev.currentTarget).serialize(),
                method: 'POST',
            }).done(function(resp) {
                if (resp.user) {
                    ev.target.style.opacity = 0;
                    self.createOrder(self);

                    // ga4 layer
                    let $payment = $('#payment'), ecommerce = $payment.data('ecommerce');
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push({
                        "event": "add_shipping_info",
                        "shipping_tier": "digital",
                        "ecommerce": ecommerce
                    });

                } else {
                    hidePleaseWait(self.overlay);
                    self.showEmailError("There was a problem with this email. Please try a different email address.")
                }
            }).fail(function(resp) {
                hidePleaseWait(self.overlay);
            })
        } else {
            hidePleaseWait(this.overlay);
        }
    }

    blur(ev) {
        // only run if the current element has an error
        if (ev.currentTarget.parentElement.classList.contains('error'))
            this.validateForm();
    }

    showEmailError(msg) {
        this.emailGroupTarget.classList.add('error', 'visible');
        this.errorMessageTarget.innerHTML = msg;
        this.errorMessageTarget.style.display = 'block';
    }

    validateForm() {
        let errors = false;
        if (this.nameTarget.value.length == 0) {
            this.nameGroupTarget.classList.add('error', 'visible');
            errors = true;
        } else {
            this.nameGroupTarget.classList.remove('error', 'visible');
        }

        if (this.emailTarget.value.length == 0) {
            this.showEmailError("We'll need a valid email address to deliver your material");
        } else {
            this.emailGroupTarget.classList.remove('error', 'visible');
            this.errorMessageTarget.style.display = 'none';
        }

        return !errors;
    }

    createOrder(self) {
        $.ajax({
            url: this.urlValue + '&clear=true',
            method: 'GET',
            statusCode: {
                409: function(resp) {
                    window.location.href = '/users/orders';
                }
            }
        }).done(function(resp) {
            // display 
            if (resp.order) {
                let $forms = $('#forms');

                if (resp.order.user_id === null) {
                    $forms.load('/landers/' + resp.order.id + '/express_registration', function() {
                        let $paymentForm = $forms.find('#payment');
                        self.setupCheckout(self, resp.order, $paymentForm);
                    });
                } else {
                    $forms.load('/landers/' + resp.order.id + '/checkout', function() {
                        let $paymentForm = $forms.find('#payment');
                        self.setupCheckout(self, resp.order, $paymentForm);
                        $paymentForm.find('#pay_by_credit_card').removeClass('hidden');
                    });
                }
                // }
            } else {
                // something odd happened
                $("button[type=submit]").removeAttr("disabled");
                hidePleaseWait(self.overlay);
            }
        }).fail(function(jqXHR, textStatus, errorThrown) {
            if (jqXHR.status == 409) {
                window.location.href = '/users/orders';
            } else {
                console.log('fail', jqXHR, textStatus, errorThrown);
                airbrake.notify(errorThrown);
                $("button[type=submit]").removeAttr("disabled");
                hidePleaseWait(self.overlay);
            }
        })
    }

    setupCheckout(self, order, $forms) {
        try {
            self.oeValue = true;
            let authenticity_token = $('meta[name="csrf-token"]').attr('content');

            let $payment = $('#payment'),
                ecommerce = $payment.data('ecommerce'),
                ppd = $payment.data('ppd'),
                svid = $payment.data('svid'),
                items = $payment.data('items');

            setupStripe(self.overlay, $forms, ecommerce);

            StripePaymentRequest({
                amount: Number.parseInt(order.subtotal * 100),
                items: self.productValue,
                authenticityToken: authenticity_token,
                pwd: self.pwdValue,
                siteVisitId: order.site_visit_id,
                url: '/orders/' + order.token + '.json',
                orderUrl: '/orders/' + order.token,
                userOrderUrl: '/users/orders',
                ecommerce: ecommerce
            });

            let ppRequest = {
                env: self.ppeValue,
                clientId: self.ppciValue,
                amount: Number.parseFloat(order.subtotal),
                items: items,
                item_total: Number.parseFloat(order.subtotal),
                discount: ppd,
                authenticityToken: authenticity_token,
                pwd: self.pwdValue,
                siteVisitId: svid,
                orderId: order.id,
                url: '/orders/' + order.token + '.json',
                orderUrl: '/orders/' + order.token,
                ecommerce: ecommerce
            }

            // first clear paypal container so we can rebuild buttons
            $('#paypal-button-container').empty();
            PayPalExpressCheckout(ppRequest);

        } catch (e) {
            console.error(e)
            airbrake.notify(e)
            $forms.animate({ opacity: 1 }, 200);
            hidePleaseWait(this.overlay);
        }
    }
}