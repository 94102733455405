// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["state", "topic"];

    connect() {
        console.log('callout#connect');
    }

    selected(ev) {
        ev.preventDefault();
        console.log(this.stateTarget.value, this.topicTarget.value)
        if (this.stateTarget.value != '' && this.topicTarget.value != '') {
            switch (this.topicTarget.value.toLowerCase()) {
                case 'dl':
                    window.location.href = `/locations/${this.stateTarget.value.toLowerCase()}/landers/cheat-sheets`
                    break;
                case 'lp':
                    window.location.href = `/locations/${this.stateTarget.value.toLowerCase()}/landers/permit-cheat-sheets`
                    break;
                case 'moto':
                    window.location.href = `/locations/${this.stateTarget.value.toLowerCase()}/landers/motorcycle-cheat-sheets`
                    break;
                case 'cdl':
                    window.location.href = `/exams/cdl/${this.stateTarget.value.toUpperCase()}`
                    break;
                default:
                    window.location.href = `/locations/${this.stateTarget.value.toLowerCase()}/landers/cheat-sheets`
            }
        } else {
            this.toggleError(this.stateTarget);
            this.toggleError(this.topicTarget);
        }
    }

    toggleError(el) {
        if (el.value == '') {
            el.classList.add('error');

            if (document.documentElement.classList.contains('ui-mobile'))
                el.parentElement.classList.add('error');
        } else {
            el.classList.remove('error');
            el.parentElement.classList.remove('error');
        }
    }
}