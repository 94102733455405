import { airbrake } from "../custom/airbrake";
import { showPleaseWait, hidePleaseWait } from "../custom/please-wait";

var cardBrandToPfClass, registerElements, setBrandIcon, setupStripe, StripePaymentRequest;

setupStripe = function(overlay, $forms, ecommerce) {
    var cardCvc, cardExpiry, cardNumber, cardZip, elementClasses, elementStyles, elements;
    elements = stripe.elements();
    elementStyles = {
        base: {
            color: '#32325D',
            fontWeight: 500,
            fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: 'rgba(0, 0, 0, 0.6)'
            },
            ':-webkit-autofill': {
                color: '#e39f48'
            }
        },
        invalid: {
            color: '#dc3545',
            '::placeholder': {
                color: '#dc3545'
            }
        }
    };
    elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid'
    };
    cardNumber = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
        placeholder: 'Card Number'
    });
    cardNumber.mount('#card-number');
    cardExpiry = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses,
    });
    cardExpiry.mount('#card-expiry');
    cardCvc = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses
    });
    cardCvc.mount('#card-cvc');
    cardZip = elements.create('postalCode', {
        style: elementStyles,
        classes: elementClasses,
        placeholder: 'Zipcode'
    });
    cardZip.mount('#card-zip');
    cardNumber.on('change', function(event) {
        if (event.brand) {
            setBrandIcon(event.brand);
        }
        console.log('here');
    });
    cardNumber.on('ready', function(event) {
        $forms.animate({ opacity: 1 }, 200, function() {
            $("button[type=submit]").removeAttr("disabled");
            hidePleaseWait(overlay);
        });
        cardNumber.focus();
    });
    $('.cvv_toggle').on('click', function(ev) {
        var $div;
        ev.preventDefault();
        $div = $('.cvv_explained');
        return $div.toggle();
    });

    return registerElements([cardNumber, cardExpiry, cardCvc, cardZip], 'pay_by_credit_card', ecommerce);
};

registerElements = function(elements, exampleName, ecommerce) {
    var disableInputs, enableInputs, error, errorMessage, example, form, formClass, savedErrors, triggerBrowserValidation;
    formClass = '#' + exampleName;
    example = document.querySelector(formClass);
    form = example.querySelector('form');
    error = form.querySelector('.error');
    errorMessage = error.querySelector('.message');
    savedErrors = {};
    enableInputs = function() {
        return Array.prototype.forEach.call(form.querySelectorAll('input[type=\'text\'], input[type=\'email\'], input[type=\'tel\']'), function(input) {
            return input.removeAttribute('disabled');
        });
    };
    disableInputs = function() {
        return Array.prototype.forEach.call(form.querySelectorAll('input[type=\'text\'], input[type=\'email\'], input[type=\'tel\']'), function(input) {
            return input.setAttribute('disabled', 'true');
        });
    };
    triggerBrowserValidation = function() {
        var submit;
        submit = document.createElement('button');
        submit.type = 'submit';
        submit.style.display = 'none';
        form.appendChild(submit);
        submit.click();
        return submit.remove();
    };
    elements.forEach(function(element, idx) {
        return element.on('change', function(event) {
            var $errorElement, $fieldError, fieldErrorSelector;
            fieldErrorSelector = $(element._component).closest('.input').data('errorid');
            $fieldError = $(fieldErrorSelector).find('.error');
            $errorElement = $('#card-errors .error');
            $errorElement.removeClass('visible');
            if (event.error) {
                $fieldError.addClass('visible');
                return $fieldError.find('.message').html(event.error.message);
            } else {
                return $fieldError.removeClass('visible');
            }
        });
    });
    return form.addEventListener('submit', function(e) {
        var $overlay, plainInputsValid;
        e.preventDefault();
        plainInputsValid = true;

        Array.prototype.forEach.call(form.querySelectorAll('input'), function(input) {
            if (input.checkValidity && !input.checkValidity()) {
                return plainInputsValid = false;
            }
        });
        if (!plainInputsValid) {
            triggerBrowserValidation();
        }
        example.classList.add('submitting');
        $overlay = showPleaseWait();

        ecommerce.payment_type = 'Credit Card'
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });  
        window.dataLayer.push({
            "event": "add_payment_info",
            "ecommerce": ecommerce
          });    

        return stripe.createToken(elements[0]).then(function(result) {
            var $errorElement;
            example.classList.remove('submitting');
            $errorElement = $('#card-errors .error');
            $errorElement.removeClass('visible');
            if (result.error) {
                if (!$('.error').hasClass('visible')) {
                    $errorElement.find('.message').html(result.error.message);
                    $errorElement.addClass('visible');
                }
                hidePleaseWait($overlay);
                return enableInputs();
            } else if (result.token) {
                $('#order_stripe_token').val(result.token.id);

                $.ajax({
                    url: form.action,
                    data: $(form).serialize(),
                    method: 'POST',
                    success: function(resp) {
                        location.href = resp.order_path;
                    },
                    error: function(resp) {
                        console.log('error', resp);
                        var error = resp.responseJSON.error.message.base.pop();
                        $errorElement.find('.message').html(error);
                        $errorElement.addClass('visible');
                        hidePleaseWait($overlay);
                    }
                })

            } else {
                hidePleaseWait($overlay);
                return enableInputs();
            }
        });
    });
};

cardBrandToPfClass = {
    'visa': 'fa-cc-visa',
    'mastercard': 'fa-cc-mastercard',
    'amex': 'fa-cc-amex',
    'discover': 'fa-cc-discover',
    'diners': 'fa-cc-diners',
    'jcb': 'fa-cc-jcb',
    'unknown': 'fa-credit-card'
};

setBrandIcon = function(brand) {
    var brandIconElement, i, pfClass;
    brandIconElement = document.getElementById('brand-icon');
    pfClass = 'fa-credit-card';
    if (brand in cardBrandToPfClass) {
        pfClass = cardBrandToPfClass[brand];
    }
    i = brandIconElement.classList.length - 1;
    while (i >= 0) {
        brandIconElement.classList.remove(brandIconElement.classList[i]);
        i--;
    }
    brandIconElement.classList.add('fa-brands', 'fa');
    return brandIconElement.classList.add(pfClass);
};

StripePaymentRequest = function(obj) {
    try {
        var requestObj = {
            country: 'US',
            currency: 'usd',
            total: {
                label: obj.items,
                amount: obj.amount
            },
            requestShipping: false,
            requestPayerName: true,
            requestPayerEmail: true
        }

        const style = {
            paymentRequestButton: {
                theme: "light",
                type: 'buy'
            }
        }

        var paymentRequest = stripe.paymentRequest(requestObj);
        var elements = stripe.elements();
        var prButton = elements.create('paymentRequestButton', {
            paymentRequest: paymentRequest,
            style: style
        }); // Check the availability of the Payment Request API first.

        var wallets;
        paymentRequest.canMakePayment().then(function(result) {
            if (result) {
                wallets = result;
                $('body').removeClass('payment-request-inactive').addClass('payment-request-active');
                prButton.mount('#payment-request-button'); // record for GTM

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'paymentRequestVisible'
                });
            } else {
                $('body').addClass('payment-request-inactive').removeClass('payment-request-active');
            }
        }).catch(function(err) {
            // ensure the credit card form is still visible and log issue
            airbrake.notify('paymentRequest.canMakePayment catch', err);
            $('body').addClass('payment-request-inactive').removeClass('payment-request-active');
        });
        prButton.on('click', function(ev) {
          // record for GTM
          if (wallets.applePay) {
            // consider this the default
            obj.ecommerce.payment_type = 'Apple Pay';
          } else if (wallets.googlePay) {
            obj.ecommerce.payment_type = 'Google Pay'
          } else if (wallets.link) {
            obj.ecommerce.payment_type = 'Link'
          } else {
            obj.ecommerce.payment_type = 'Unknown'
          };

          window.dataLayer.push({ ecommerce: null });  
          window.dataLayer.push({
              "event": "add_payment_info",
              "ecommerce": obj.ecommerce
            });    

            $('#card-errors .error').removeClass('visible');
        });
        paymentRequest.on('token', function(ev) {
            // record for GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'paymentRequestSubmitted'
            });

            fetch(obj.url, {
                method: 'PATCH',
                body: JSON.stringify({
                    authenticity_token: obj.authenticityToken,
                    order: {
                        stripe_token: ev.token.id,
                        cc_form: true,
                        bypass_address_validation: true,
                        terms_of_sale: '1',
                        user_attributes: {
                            password: obj.pwd,
                            password_confirmation: obj.pwd,
                            site_visit_id: obj.siteVisitId,
                            email: ev.payerEmail,
                            name: ev.payerName
                        }
                    }
                }),
                headers: {
                    'content-type': 'application/json'
                },
                credentials: 'same-origin'
            }).then(function(resp) {
                return resp.json();
            }).then(function(response) {
                if (response && response.ok) {
                    ev.complete('success', response);
                    window.location.href = obj.orderUrl;
                } else {
                    ev.complete('fail');

                    if (response.status == 409) {
                        window.location.href = obj.userOrderUrl;
                    }
                }
            }).catch(function(err) {
                airbrake.notify('paymentRequest.on token catch', err);
            });
        });
    } catch (err) {
        airbrake.notify('StripePaymentRequest', err);
        $('body').addClass('payment-request-inactive').removeClass('payment-request-active');
    }
};

export { setupStripe, StripePaymentRequest };