import { Controller } from "stimulus"
import { airbrake } from "../custom/airbrake";

export default class extends Controller {
    static values = { url: String}

    connect() {
        console.log('admin_question#connect')
    }

    toggle(ev) {
        ev.preventDefault();
        console.log('toggle', ev.params.status)

        const data = `question[status]=${ev.params.status}`;

        $.ajax({
            url: this.urlValue,
            data: data,
            type: 'PUT',
            dataType: 'js',
            success: function(data) {
                console.log(data);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(errorThrown);
            }
        });
    }
}