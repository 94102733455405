// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["output"];
    static values = { exp: String };

    connect() {
        console.log('select_location#connect', this.expValue);

        switch (this.expValue) {
            case 'geo':
                this.selectState(false);
                break;
            case 'redirect':
                this.selectState(true);
                break;
        }
    }

    selectState(submit) {
        var self = this;
        if (sessionStorage && sessionStorage.userLocation !== undefined) {
            var state = sessionStorage.userLocation;
            self.changeState(state, submit);
        } else {
            fetch('https://us-central1-bustling-day-251215.cloudfunctions.net/geolocation')
                .then(resp => resp.json())
                .then(resp => {
                    console.log('resp', resp)
                    if (resp.country == 'US') {
                        var states = { "AL": "Alabama", "AK": "Alaska", "AZ": "Arizona", "AR": "Arkansas", "CA": "California", "CO": "Colorado", "CT": "Connecticut", "DE": "Delaware", "DC": "District Of Columbia", "FL": "Florida", "GA": "Georgia", "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "IA": "Iowa", "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MD": "Maryland", "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", "MO": "Missouri", "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", "NH": "New Hampshire", "NJ": "New Jersey", "NM": "New Mexico", "NY": "New York", "NC": "North Carolina", "ND": "North Dakota", "OH": "Ohio", "OK": "Oklahoma", "OR": "Oregon", "PA": "Pennsylvania", "PR": "Puerto Rico", "RI": "Rhode Island", "SC": "South Carolina", "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VT": "Vermont", "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming" };
                        var state = resp.region.toUpperCase();

                        if (state !== undefined && state != '?') {
                            sessionStorage.userLocation = state;
                            self.changeState(state, submit);
                        } else {
                            // default to CA since no state found
                            self.changeState('CA', submit);
                        }
                    } else {
                        // default to CA since outside the US
                        self.changeState('CA', submit);
                    }
                })
                .catch(e => {
                    console.log('ERROR: ', e)
                    self.changeState('CA', submit);
                })
        }
    }

    changeState(state, submit) {
        if (state !== undefined) {
            if (submit) {
                var product_type = $('#product_type').val()
                location.href = '/cart/' + state.toUpperCase() + '/' + product_type + '/cs'
            } else {
                document.getElementById('state').value = state.toUpperCase();
                $('#state').selectmenu('refresh');
                DMVCS.apps_dmvcs_dmv_cheat_sheets.activateButtons(state);
            }
        }
    }
}