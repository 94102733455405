import { Spinner } from 'spin.js';
var iosOverlay = require('ios-overlay');

function showPleaseWait() {
    var opts, spinner, target;
    opts = {
        lines: 13,
        length: 11,
        width: 5,
        radius: 17,
        corners: 1,
        rotate: 0,
        color: '#FFF',
        speed: 1,
        trail: 60,
        shadow: false,
        hwaccel: false,
        className: 'ios_spinner',
        zIndex: 2e9,
        top: 'auto',
        left: 'auto'
    };
    document.body.classList.add('overlay');

    target = document.createElement('div');
    document.body.appendChild(target);
    spinner = new Spinner(opts).spin(target);
    return iosOverlay({
        text: 'Please wait...',
        duration: 60000,
        spinner: spinner
    });
};

function hidePleaseWait(obj) {
    document.body.classList.remove('overlay')

    if (obj) {
        obj.destroy();
    }
}

export {showPleaseWait, hidePleaseWait};