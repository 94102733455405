import { Notifier } from '@airbrake/browser';

const airbrake = new Notifier({
    projectId: 83646,
    projectKey: 'aead7a1391530b973d09109e2279d252'
});

airbrake.addFilter((notice) => {
    notice.context.environment = process.env.PIPE_ENV;
    if (notice.context.environment == 'development') {
        console.log('Airbrake yarn', notice.errors)
        return null;
    } else {
        if (process.env.AIRBRAKE_JS == 1) {
            return notice;
        } else {
            return null;
        }
    }
});

export { airbrake };