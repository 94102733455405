import { Controller } from "stimulus"
import { airbrake } from "../custom/airbrake";
import { setupStripe, StripePaymentRequest } from "../custom/stripe";
import { PayPalExpressCheckout } from "../custom/paypal";
import { showPleaseWait, hidePleaseWait } from "../custom/please-wait";

export default class extends Controller {
    static targets = ["cs", "pt", "bundle", "name", "email", "nameGroup", "emailGroup", "errorMessage"]
    static values = { url: String, product: String, oe: Boolean, ppe: String, ppci: String, pwd: String }

    connect() {
        console.log('checkout#connect')
        if (this.oeValue) {
            this.createOrder(this, false);
        }
    }

    planSelect(ev) {
        ev.preventDefault();
        if (this.urlValue != ev.params.url) {
            this.urlValue = ev.params.url;
            this.productValue = ev.params.product;

            $('.plans .btn').removeClass('selected');
            $(ev.params.type).addClass('selected');
            $('.plans').removeClass('checkout').find('.btn').show();

            // if on checkout step, we need to reload the order
            if (this.oeValue) {
                this.overlay = showPleaseWait();
                this.createOrder(this, true);
            }
        }

        document.getElementById('checkout').scrollIntoView({
            behavior: 'smooth'
        });
    }

    submit(ev) {
        ev.preventDefault();

        // $("button[type=submit]").attr("disabled", 'disabled').html("<em class='fa fa-spinner fa-spin'/>");
        this.overlay = showPleaseWait();

        let self = this;
        if (this.validateForm()) {
            // first we submit to new order to create and order and add the product
            $.ajax({
                url: ev.currentTarget.action,
                data: $(ev.currentTarget).serialize(),
                method: 'POST',
            }).done(function(resp) {
                if (resp.user) {
                    ev.target.style.opacity = 0;
                    self.createOrder(self, true);
                }
            }).fail(function(resp) {
                hidePleaseWait(this.overlay);
            })
        } else {
            hidePleaseWait(this.overlay);
        }
    }

    blur(ev) {
        // only run if the current element has an error
        if (ev.currentTarget.parentElement.classList.contains('error'))
            this.validateForm();
    }

    validateForm() {
        let errors = false;
        if (this.nameTarget.value.length == 0) {
            this.nameGroupTarget.classList.add('error');
            errors = true;
        } else {
            this.nameGroupTarget.classList.remove('error');
        }

        if (this.emailTarget.value.length == 0) {
            this.emailGroupTarget.classList.add('error');
            this.errorMessageTarget.style.display = 'block';
            errors = true;
        } else {
            this.emailGroupTarget.classList.remove('error');
            this.errorMessageTarget.style.display = 'none';
        }

        return !errors;
    }

    createOrder(self, reloadForm) {
        $.ajax({
            url: this.urlValue + '&clear=true',
            method: 'GET',
            statusCode: {
                409: function(resp) {
                    location.href = '/users/orders';
                }
            }
        }).done(function(resp) {
            // display 
            if (resp.order) {
                let $forms = $('#forms');

                if (reloadForm) {
                    $forms.load('/landers/' + resp.order.id + '/checkout', function() {
                        let $paymentForm = $forms.find('#payment');
                        self.setupCheckout(self, resp.order, $paymentForm);
                    });
                } else {
                    let $paymentForm = $forms.find('#payment');
                    self.setupCheckout(self, resp.order, $paymentForm);
                }
                // }
            } else {
                // something odd happened
                $("button[type=submit]").removeAttr("disabled");
                hidePleaseWait(self.overlay);
            }
        }).fail(function(resp) {
            airbrake.notify('createOrder', resp);
            $("button[type=submit]").removeAttr("disabled");
            hidePleaseWait(self.overlay);
        })
    }

    setupCheckout(self, order, $forms) {
        try {
            setupStripe(self.overlay, $forms);

            self.oeValue = true;

            let authenticity_token = $('meta[name="csrf-token"]').attr('content');

            StripePaymentRequest({
                amount: Number.parseInt(order.subtotal * 100),
                items: self.productValue,
                authenticityToken: authenticity_token,
                pwd: self.pwdValue,
                siteVisitId: order.site_visit_id,
                url: '/orders/' + order.token + '.json',
                orderUrl: '/orders/' + order.token,
                userOrderUrl: '/users/orders'
            });

            let $payment = $('#payment'),
                ppd = $payment.data('ppd'),
                svid = $payment.data('svid'),
                email = $payment.data('email'),
                items = $payment.data('items');

            let ppRequest = {
                env: self.ppeValue,
                clientId: self.ppciValue,
                amount: Number.parseFloat(order.subtotal),
                items: items,
                item_total: Number.parseFloat(order.subtotal),
                discount: ppd,
                authenticityToken: authenticity_token,
                email: email,
                pwd: self.pwdValue,
                siteVisitId: svid,
                orderId: order.id,
                url: '/orders/' + order.token + '.json',
                orderUrl: '/orders/' + order.token
            }

            // first clear paypal container so we can rebuild buttons
            $('#paypal-button-container').empty();
            PayPalExpressCheckout(ppRequest);

        } catch (e) {
            airbrake.notify('setupCheckout', e)
            $forms.animate({ opacity: 1 }, 200);
            hidePleaseWait(this.overlay);
        }
    }
}