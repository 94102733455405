import { airbrake } from "../custom/airbrake";
import { showPleaseWait, hidePleaseWait } from "../custom/please-wait";

export function PayPalExpressCheckout(obj) {
    var showError, showLoginMessage;

    paypal.Buttons({
        style: {
            layout: 'horizontal',
            shape: 'rect',
            tagline: false,
            height: 40
        },
        createOrder: function(data, actions) {
            obj.ecommerce.payment_type = 'PayPal'
            window.dataLayer.push({ ecommerce: null });  
            window.dataLayer.push({
                "event": "add_payment_info",
                "ecommerce": obj.ecommerce
              });    
            
            return actions.order.create({
                purchase_units: [{
                    soft_descriptor: 'DMVCHEATSHEETS',
                    amount: {
                        value: obj.amount,
                        current_code: 'USD',
                        breakdown: {
                            item_total: {
                                currency_code: 'USD',
                                value: obj.item_total
                            },
                            discount: {
                                currency_code: 'USD',
                                value: obj.discount
                            }
                        }
                    },
                    custom_id: obj.orderId,
                    items: obj.items
                }],
                application_context: {
                    brand_name: 'DMVCheatSheets.com',
                    shipping_preference: 'NO_SHIPPING',
                    user_action: 'PAY_NOW'
                }
            });
        },
        onClick: function() {
            $('#card-errors .error').removeClass('visible');
        },
        onApprove: function(data, actions) {
            var overlay;

            $('.body_container').addClass('dimmed');
            overlay = showPleaseWait();

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'payPalSubmitted'
            });

            // This function captures the funds from the transaction.
            return actions.order.capture().then(function(details) {

                // figure out how to handle email
                var email = obj.email;
                if (email == '' || email === undefined || email === null) {
                    email = details.payer.email_address;
                }

                let orderData = {
                    authenticity_token: obj.authenticityToken,
                    order: {
                        paypal_order_id: data.orderID,
                        source: 'paypal',
                        cc_form: false,
                        bypass_address_validation: true,
                        terms_of_sale: '1',
                        user_attributes: {
                            password: obj.pwd,
                            password_confirmation: obj.pwd,
                            site_visit_id: obj.siteVisitId,
                            email: email,
                            name: details.payer.name.given_name + ' ' + details.payer.name.surname
                        }
                    }
                }

                // Call your server to save the transaction
                fetch(obj.url, {
                    method: 'PATCH',
                    body: JSON.stringify(orderData),
                    headers: {
                        'content-type': 'application/json'
                    },
                    credentials: 'same-origin'
                }).then(function(resp) {
                    return resp.json();
                }).then(function(response) {
                    if (response.ok) {
                        window.location.href = obj.orderUrl;
                    } else if (response.status == 409) {
                        // window.location.href = response.path;
                        hidePleaseWait(overlay);
                        showLoginMessage(response.path);
                    } else {
                        airbrake.notify('PayPal response failed', { response: response });
                        hidePleaseWait(overlay);
                        showError(response.error);
                    }
                }).catch(function(err) {
                    airbrake.notify(err);
                    hidePleaseWait(overlay);

                    showError(err);
                });
            });
        },
        onError: function(err) {
            console.log('onError', err)

            showError(err);

            airbrake.notify(err);
        }
    }).render('#paypal-button-container').then(function() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'payPalVisible'
        });

        $('body').addClass('paypal-active');
    }).catch(function(err) {
        showError(err);
        airbrake.notify(err);
        $('body').removeClass('paypal-active').addClass('paypal-inactive');
    });

    showError = function(err) {
        $('#paypal-error span.message').html('There is a problem with PayPal. Please try again or use a credit card');
        $('#paypal-error .error').addClass('visible');

        console.log('PayPal Error: ', err)
    }

    showLoginMessage = function(path) {
        var link = '<a href="' + path + '">Click here</a> to login to view it.';
        $('#paypal-error span.message').html('You already own this material. ' + link)
        $('#paypal-error .error').addClass('visible');
    }



}