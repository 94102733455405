// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["output"];
    static values = { url: String, debug: Boolean, regionCode: String };

    connect() {
        console.log('landers#old', this.regionCodeValue)

        if (this.debugValue) {
            return
        }

        if (sessionStorage && sessionStorage.userLocation !== undefined) {
            var state = sessionStorage.userLocation;
            location.href = this.urlValue.replace('STATE', state);
        } else {
            if (this.regionCodeValue) {
                sessionStorage.userLocation = state;
                location.href = this.urlValue.replace('STATE', this.regionCodeValue);
            } else {
                fetch('https://us-central1-bustling-day-251215.cloudfunctions.net/geolocation')
                    .then(resp => resp.json())
                    .then(resp => {
                        console.log("resp", resp);
                        if (resp.country == 'US') {
                            var states = { "AL": "Alabama", "AK": "Alaska", "AZ": "Arizona", "AR": "Arkansas", "CA": "California", "CO": "Colorado", "CT": "Connecticut", "DE": "Delaware", "DC": "District Of Columbia", "FL": "Florida", "GA": "Georgia", "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "IA": "Iowa", "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MD": "Maryland", "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", "MO": "Missouri", "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", "NH": "New Hampshire", "NJ": "New Jersey", "NM": "New Mexico", "NY": "New York", "NC": "North Carolina", "ND": "North Dakota", "OH": "Ohio", "OK": "Oklahoma", "OR": "Oregon", "PA": "Pennsylvania", "PR": "Puerto Rico", "RI": "Rhode Island", "SC": "South Carolina", "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VT": "Vermont", "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming" };
                            var state = resp.region.toUpperCase();

                            if (state !== undefined && state != '?') {
                                sessionStorage.userLocation = state;
                                location.href = this.urlValue.replace('STATE', state);
                            } else {
                                // default to CA since no state found
                                location.href = this.urlValue.replace('STATE', 'CA');
                            }
                        } else {
                            // default to CA since no state found
                            location.href = this.urlValue.replace('STATE', 'CA');
                        }
                    })
                    .catch(e => {
                        console.log('ERROR: ', e)
                        location.href = this.urlValue.replace('STATE', 'CA');
                    })
            }
        }
    }

    show_location(ev) {
        ev.preventDefault();

        fetch('https://us-central1-bustling-day-251215.cloudfunctions.net/geolocation')
            .then(resp => resp.json())
            .then(resp => {
                console.log(resp);
            })
            .catch(e => {
                console.log('ERROR: ', e)
            })
    }
}