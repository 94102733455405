import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["output"]

    connect() {
        console.log('lightbox#connect')
    }

    open(ev) {
        let image = document.getElementById('lightBoxImage');
        let url = ev.currentTarget.getAttribute('src');
        image.setAttribute('src',url);

        const lbModal = new bootstrap.Modal('#lightBox', {
            keyboard: false
        });

        lbModal.show();
    }
}